import React from 'react';
import JulyWhyPaidy from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyWhyPaidy';
import Img1 from 'src/images/why-paidy-1.png';
import Img2 from 'src/images/why-paidy-2.png';

const DATA = [
  {
    key: 1,
    img: Img1,
    title: (
      <>
        ３・６回あと払いが
        <br />
        分割手数料無料*
      </>
    ),
    text:
      '分割手数料無料*で、お支払いを３・６回に分けることができます。お支払い総額はそのままで月々のお支払いを調整できるので、よりかしこく計画的にお買い物を楽しめます。',
    button: {
      text: '３・６回あと払いの詳細を見る',
      to: 'https://paidy.com/npay',
    },
  },
  {
    key: 2,
    img: Img2,
    title: 'ペイディカードでどこでも',
    text:
      'Visaマークのあるお店でクレジットカードとしてお買い物を楽しめます。ネットショップで使えるバーチャルカードは最短5分で無料・即日発行。さらにリアルカードを発行すると、街のお店でも使えます。',
    button: {
      text: 'ペイディカードの詳細を見る',
      to: 'https://paidy.com/paidycard/',
    },
  },
];

const DESCRIPTION = (
  <>
    *口座振替・銀行振込のみ無料
    <br />
    ※選択できる支払い回数は加盟店により異なります。
    <br />
    ※３回あと払いは1回のお買い物につき、Amazonは3円以上、その他のショップでは3,000円以上のお支払いで利用可能です。6回あと払いは1回のお買い物につき、Amazonは6円以上、その他のショップでは6,000円以上のお支払いで利用可能です。
  </>
);

export default function CashbackWhyPaidy({ id }) {
  return <JulyWhyPaidy data={DATA} description={DESCRIPTION} id={id} />;
}
