import React from 'react';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPaymentSteps';
import CashbackCondition from 'src/components/Merchant/Campaign/GeneralCashback202303/CashbackCondition';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyAppDownload';
import CashbackWhyPaidy from 'src/components/Merchant/Campaign/GeneralCashback202303/CashbackWhyPaidy';
import CashbackTopBanner from 'src/components/Merchant/Campaign/GeneralCashback202303/CashbackTopBanner';
import CashbackBannerBottom from 'src/components/Merchant/Campaign/GeneralCashback202303/CashbackBannerBottom';
import { SITE_METADATA, PAIDY_HOST } from 'src/constants';
import OgpImg from 'src/images/campaign-202303/202303_TCMP_OGP_v1.jpg';

const SEOProps = {
  ogImage: OgpImg,
  twitterImage: `${PAIDY_HOST}/tvcm_ogp_v2.jpg`,
  title: SITE_METADATA.general_cashback_202303.title,
  description: SITE_METADATA.general_cashback_202303.description,
};

export default function GeneralCashback202303() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <CashbackTopBanner />
      <JulyPayLater description="AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。" />
      <JulyPaymentSteps />
      <CashbackCondition />
      <CashbackBannerBottom />
      <CashbackWhyPaidy />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
