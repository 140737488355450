import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import JulyCondition from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyConditions';

const DATA = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        キャンペーン期間中に決済画面でペイディの翌月あと払い、
        <OutboundLink
          href="https://paidy.com/npay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ３・６回あと払い
        </OutboundLink>
        、もしくは
        <OutboundLink
          href="https://paidy.com/paidycard/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディカード
        </OutboundLink>
        を利用でお買い物すると、抽選で100名様に全額（最大10万円）キャッシュバックが当たる。
        <br />
        <br />
        <small>
          ※キャンペーン期間中に上記条件でお買い物された方に、4月30日までにキャッシュバックを実施。4月30日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
        </small>
      </>
    ),
  },
  {
    title: <strong>キャンペーン概要</strong>,
    content: '2023年3月16日（木）06:00～4月15日（土）23:59',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・全額キャッシュバックの当選はお一人一回までで、期間中のお買い物総額を対象として抽選でキャッシュバックされます（最大10万円）。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・当選者には2023年4月30日（日）までにキャッシュバックをいたします。
        <br />
        ・キャッシュバックは、ペイディのご利用に応じた請求金額より差し引く方法による付与されます。いかなる場合でも、キャッシュバック金額を現金で交付することはできませんのでご了承ください。
        <br />
        ・同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        <br />
        ・Amazonでのお買い物の場合、期間中に発送（出荷）された商品の総額がキャンペーン対象となります。Amazon
        Primeのお申し込み、Amazon
        Musicの定額ストリーミングサービスのプラン料金など期間中に決済の発生しないサービスの支払いはキャッシュバックの対象外となります。
        <br />
        ・期間中のお買い物でキャンセルされた商品は、キャッシュバックの対象外となります。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディキャッシュバック及びクーポン利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは{' '}
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ヘルプ
        </OutboundLink>
        からご連絡ください。
      </>
    ),
  },
];

export default function CashbackTopBanner({ id }) {
  return <JulyCondition data={DATA} id={id} />;
}
